module.exports = {
	host: 'https://biz.mail.ru',
	pages: {
		workSpace: '/',
		workMail: '/mail/',
		workMessenger: '/messenger/',
		workDisk: '/teambox/',
		office: '/office/',
		teams: '/teams/',
		teamsDownload: '/teams/download/',
		verify: '/verify/',
		contacts: '/contacts/',
		404: '/404',
		'old-browser': '/old-browser',
		onpremise: '/onpremise/',
		education: '/edu/',
		tariffs: '/tariffs/',
		smallMediumBusiness: '/smb/',
		partners: '/partners/',
		research: '/research/',
		promo: '/promo/',
		retail: '/retail/',
		meetings: '/meetings/',
		security: '/security/',
		docs: '/docs/',
		saas: '/saas/',
	}
}
