import {Dialog} from '@/components/base/dialog/Dialog';
import CreateProjectForm from '@/components/common/forms/create-project-form/CreateProjectForm';
import {getAvatarUrl, UserInfo} from '@/components/common/user-info/UserInfo';
import {injectProperty} from '@/container/injections';
import {activeUser} from '@/hooks/use-get-active-user';
import {IApiService} from '@/services/api/IApiService';
import {ILightboxService} from '@/services/lightbox/ILightboxService';
import {ReadonlyLightbox} from '@/services/lightbox/interfaces';
import {XrayEventId} from '@/services/metrics/constants';
import {IMetricsService} from '@/services/metrics/IMetricsService';
import {clearCreateProjectDialogOpened} from '@/utilites/createProjectDialogHelpers/clearCreateProjectDialogOpened';
import {setCreateProjectDialogOpened} from '@/utilites/createProjectDialogHelpers/setCreateProjectDialogOpened';

export class CreateProjectDialog extends ReadonlyLightbox {
	@injectProperty(IApiService) protected apiService!: IApiService;
	@injectProperty(ILightboxService) protected lightboxService!: ILightboxService;
	@injectProperty(IMetricsService) protected readonly metricsService!: IMetricsService;

	handleValidSubmit = () => this.props.onClose();
	handleClose = () => this.props.onClose();

	onLoginChangeClick() {
		setCreateProjectDialogOpened();

		window.__PH?.authForm?.once('hide', () => {
			clearCreateProjectDialogOpened();
		});

		window.__PH?.authForm?.show({
			formType: 'relogin',
			mode: 'simple',
			type: 'choice',
			action: 'choice',
			choice_for_relogin: '1',
		});

		this.metricsService.sendEvent({
			xrayEvents: [
				{
					name: XrayEventId.clickChangeAuth,
				},
			],
		});
	}

	render() {
		const email = activeUser.email;
		const fullName = activeUser.fullName;
		const avatar = getAvatarUrl(email, 48);
		return (
			<Dialog.Body>
				{email && fullName && (
					<UserInfo
						email={email}
						avatar={avatar}
						fullName={fullName}
						onClick={this.onLoginChangeClick}
					/>
				)}
				<CreateProjectForm
					onValidSubmit={this.handleValidSubmit}
					onClose={this.handleClose}
					onLoginChangeClick={this.onLoginChangeClick}
				/>
			</Dialog.Body>
		);
	}
}
